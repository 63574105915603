import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import Footer from '../../elements/footer';
import TopBar from '../../elements/topBar';

const EmptyCart = () => {

    useEffect(() => {

        window.scrollTo({
            top: 0
        })

    }, []);

    return <>
        <Container style={{  }} className="alignedCenter">
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, paddingBottom: 50, minHeight: "calc(100vh - 207px)" }}>
                    <div>
                        <h2 className='normal' style={{ marginBottom: 10 }}>Warenkorb ist leer!</h2>

                        <h5 className='normal'>Es befindet sich keine Retterbox im Warenkorb.</h5>

                        <br />
                        <br />

                        <a href='https://afreshed.at/' className="button">Zurück zu den Produkten</a>
                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default EmptyCart;