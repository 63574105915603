import React, { useEffect, useState } from 'react';

import styles from './style.module.scss';

const Accordion = ({ open, setOpen, id, title, children, rightContent, show, style }) => {

    const [display, setDisplay] = useState(show);
    const [visible, setVisible] = useState(show);
    const [showBackButton, setShowBackButton] = useState(false);

    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        if (show === true) {
            setDisplay(true);
            setTimeout(() => {
                setVisible(true);
            }, 100);
        } else {
            setVisible(false);
            setTimeout(() => {
                setDisplay(false);
            }, 200);
        }

    }, [show])

    useEffect(() => {
        if (open === true) {
            setTimeout(() => {
                setIsOpen(true);

                setTimeout(() => {
                    setShowBackButton(true);
                }, 100)
            }, 200);
        } else {
            setShowBackButton(false);
            setIsOpen(false);
        }

    }, [open])

    // if (!display) {
    //     return null;
    // }

    return <div className={`${styles.container} ${isOpen ? styles.open : undefined} ${display ? styles.display : undefined} ${visible ? styles.visible : undefined}`}>
        {isOpen && <div className={styles.otherPayment} style={{ opacity: (showBackButton ? 1 : 0) }} onClick={() => setOpen(null)}>andere Zahlmethode</div>}
        <div className={styles.header}
            style={style}
            onClick={() => setOpen(id)}>
            <div className={styles.title}>{title}</div>
            {rightContent && <div className={styles.right} style={{ textAlign: 'right' }}>{rightContent}</div>}
        </div>
        <div className={`${styles.content} ${open ? styles.show : undefined}`}>{children}</div>
    </div >
}

export default Accordion;
