import React, { useEffect, useRef, useState } from 'react';

import wrlog from '../../helper/functions/wrlog';
import CardPayment from './mollie/card';
import PaypalPayment from './mollie/paypal';
import SepaPayment from './mollie/sepa';
import { useCookies } from 'react-cookie';

import OrderUnit from '../../communicator/http/OrderUnit';
import { useNavigate } from 'react-router-dom';
import KlarnaPayment from './klarna';
import Accordion from '../accordion';
import styles from './mollie/style.module.scss';
import { Col, Container, Row } from 'react-bootstrap';

import Kreditkarte from "../../assets/Credit_card.png";
import SofortPayment from './mollie/sofort';
import Popup from '../popup';
import { MOLLIE_KEY, MOLLIE_TEST } from '../../config';
const images = <img alt="Kredikarten Logos" src={Kreditkarte} />;


const PaymentMethods = ({ setLoading }) => {
    const navigate = useNavigate();


    const [cookies, setCookies] = useCookies(['afreshedCart']);

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [mounted, setMounted] = useState(false);

    const orderUnit = new OrderUnit();

    const ref = useRef();

    const [cardHolderValid, setCardHolderValid] = useState(false);
    const [cardNumberValid, setCardNumberValid] = useState(false);
    const [cardExpiryValid, setCardExpiryValid] = useState(false);
    const [cardVerificationCodeValid, setCardVerificationCodeValid] = useState(false);

    const [paymentPopupOpen, setPaymentPopupOpen] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {

        var mollie = window.Mollie(MOLLIE_KEY, { locale: 'de_AT', testmode: MOLLIE_TEST });

        if (mounted) {
            return;
        }

        setMounted(true);
        var cardHolder = mollie.createComponent('cardHolder');
        var cardNumber = mollie.createComponent('cardNumber');
        var expiryDate = mollie.createComponent('expiryDate');
        var verificationCode = mollie.createComponent('verificationCode');

        cardHolder.mount('#card-holder');
        cardNumber.mount('#card-number');
        expiryDate.mount('#card-expiry');
        verificationCode.mount('#card-verification-code');

        wrlog("cardHolder", cardHolder);

        cardHolder.addEventListener('change', event => {
            if (event.valid) {
                setCardHolderValid(true);
            } else {
                setCardHolderValid(false);
            }
        });


        cardNumber.addEventListener('change', event => {
            if (event.valid) {
                setCardNumberValid(true);
            } else {
                setCardNumberValid(false);
            }
        });


        expiryDate.addEventListener('change', event => {
            if (event.valid) {
                setCardExpiryValid(true);
            } else {
                setCardExpiryValid(false);
            }
        });

        verificationCode.addEventListener('change', event => {
            if (event.valid) {
                setCardVerificationCodeValid(true);

            } else {
                setCardVerificationCodeValid(false);
            }
        });

        let form = document.getElementById('form');

        form.addEventListener('submit', e => {
            wrlog(selectedPaymentMethod);
            e.preventDefault();

            wrlog("asd", form.getAttribute('selectedpaymentmethod'))

            initPayment(mollie, form.getAttribute('selectedpaymentmethod'));
        });
    }, [selectedPaymentMethod])


    useEffect(async () => {
        if (cardHolderValid && cardNumberValid && cardExpiryValid && cardVerificationCodeValid) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [cardHolderValid, cardNumberValid, cardExpiryValid, cardVerificationCodeValid])

    const getPaymentDetails = (paymentProvider, method, additionalData = {}) => {

        setLoading(true);

        let items = [];

        cookies.afreshedCart.items.forEach(item => {
            items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        });

        let body = {
            customer: {
                email: cookies.afreshedCart.customer.email,
                first_name: cookies.afreshedCart.customer.firstName,
                last_name: cookies.afreshedCart.customer.lastName,
                address_1: cookies.afreshedCart.customer.address1,
                street_number: cookies.afreshedCart.customer.streetNumber,
                address_2: cookies.afreshedCart.customer.address2,
                postcode: cookies.afreshedCart.customer.postcode,
                city: cookies.afreshedCart.customer.city,
                legal_text: cookies.afreshedCart.customer.legalText,
                receive_news: cookies.afreshedCart.customer.receiveNews,
                phone: cookies.afreshedCart.customer.phone || '',
            },
            items: items,
            payment: {
                provider: paymentProvider,
                methodId: method,
            },
            coupons: cookies.afreshedCart.coupons.map(c => c.code)
        }

        if (method === 'creditcard') {
            body.payment_data = additionalData.cardToken;
        } else if (method === 'sepa') {
            body.payment_data = additionalData;
        }

        const cbSuccess = (res) => {
            wrlog("RESULT", res);
            setLoading(false);

            if (paymentProvider === 'paypal') {
                if (res.returnUrl !== undefined) {
                    // setRedirectUrl(res.returnUrl);
                    // setPaymentPopupOpen(true);

                    window.location.replace(res.returnUrl);
                }
            } else if (paymentProvider === 'mollie') {
                if (res.returnUrl !== undefined) {
                    // setRedirectUrl(res.returnUrl);
                    // setPaymentPopupOpen(true);

                    window.location.replace(res.returnUrl);
                } else if (res.id !== undefined) {
                    navigate(`/waiting/${res.id}`);
                }
            }
            else if (paymentProvider === 'klarna') {
                if (res.returnUrl !== undefined) {
                    navigate(`/waiting/${res.id}`);
                }
            }
        }

        const cbError = (err) => {
            wrlog("ERROR", err);
            setLoading(false);
        }

        orderUnit.addOrder(body, cbSuccess, cbError);
    }

    const initPayment = async (mollie, paymentMethod) => {
        let additionalData = {};

        if (paymentMethod === 'creditcard') {
            let { token, error } = await mollie.createToken();

            if (error) {
                wrlog(error);
                return false;
            }

            additionalData.cardToken = token;
        } else if (paymentMethod === 'sepa') {
            let el = document.getElementById('sepa-iban-input');
            additionalData.iban = el.value;
        }

        let provider = '';

        if (paymentMethod === 'creditcard' || paymentMethod === 'paypal' || paymentMethod === 'sofort' || paymentMethod === 'sepa') {
            provider = 'mollie';
        }

        //AT69 1500 0008 2108 0686

        getPaymentDetails(provider, paymentMethod, additionalData);

    }

    return <div style={{ marginTop: 50, position: 'relative' }}>
        <form id="form" selectedpaymentmethod={selectedPaymentMethod}>
            <Accordion
                show={selectedPaymentMethod === 'creditcard' || selectedPaymentMethod === null}
                setOpen={setSelectedPaymentMethod}
                open={selectedPaymentMethod === 'creditcard'}
                id={"creditcard"}
                title={"Kreditkarte"}
                rightContent={images}
            >
                <div className={`content ${styles.elementContainer}`}>
                    <Container fluid style={{ padding: 0 }}>
                        <Row>
                            <Col md={12} style={{ marginBottom: 20 }}>
                                <div ref={ref} id="card-holder">
                                    <label>Kartenbesitzer</label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div ref={ref} id="card-number">
                                    <label>Kartennummer</label>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div ref={ref} id="card-expiry">
                                    <label>Ablaufdatum</label>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div ref={ref} id="card-verification-code">
                                    <label>Sicherheitscode (CVV)</label>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Accordion>

            <SofortPayment
                show={selectedPaymentMethod === 'sofort' || selectedPaymentMethod === null}
                setOpen={setSelectedPaymentMethod}
                open={selectedPaymentMethod === 'sofort'}
                setValid={setIsValid}
            />
            <SepaPayment
                show={selectedPaymentMethod === 'sepa' || selectedPaymentMethod === null}
                setOpen={setSelectedPaymentMethod}
                open={selectedPaymentMethod === 'sepa'}
                setValid={setIsValid}
            />
            <PaypalPayment
                show={selectedPaymentMethod === 'paypal' || selectedPaymentMethod === null}
                setOpen={setSelectedPaymentMethod}
                open={selectedPaymentMethod === 'paypal'}
                setValid={setIsValid}
            />
            <input type={'submit'}
                className={`button continueButton ${!isValid ? 'disabled' : undefined}`} value="Kostenpflichtig bestellen" />
        </form>
        <Popup
            close={() => setPaymentPopupOpen(false)}
            open={paymentPopupOpen}
            style={{ width: '100%', maxWidth: 600, height: '90vh' }}
        >
            <iframe
                src={redirectUrl}
                style={{ width: '100%', height: '100%' }}

            ></iframe>
        </Popup>
        {/* <KlarnaPayment
            show={selectedPaymentMethod === 'klarna' || selectedPaymentMethod === null}
            setOpen={setSelectedPaymentMethod}
            open={selectedPaymentMethod === 'klarna'}
            setValid={setIsValid}
            setReturnData={setKlarnaData}
        /> */}

    </div >

}

export default PaymentMethods;