import React, { useState, useEffect } from 'react';
import { formatMoney } from '../../helper/functions/formatPrice';
import styles from './style.module.scss';
import { useCookies } from 'react-cookie';

import Kalender from "../../assets/calendar-check.png";
import Geld from "../../assets/money.png";
import Baum from "../../assets/tree-line.png";
import Mc from "../../assets/mastercardG.png";
import Visa from "../../assets/visaG.png";
import PayPal from "../../assets/cc-paypalG.png";
import Sepa from "../../assets/SEPAG.png";
import Gp from "../../assets/googlepayG.png";
import Ap from "../../assets/apple-payG.png";
import Ccdc from "../../assets/cc-diners-clubG.png";
import Ae from "../../assets/american-expressG.png";
import CartUnit from '../../communicator/http/CartUnit';
import { TextField } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import CouponUnit from '../../communicator/http/Coupon';
import wrlog from '../../helper/functions/wrlog';
import { Skeleton } from '@material-ui/lab';



const CartSidebar = ({ mobile, scrollToCart, initRef, email, setCouponsToParent }) => {

    const [cookies, setCookies] = useCookies(['afreshedCart']);

    const [totals, setTotals] = useState({ total: 0, tax: 0 });
    const [subTotals, setSubTotals] = useState({ total: 0, tax: 0 });
    const [couponCode, setCouponCode] = useState('');
    const [coupons, setCoupons] = useState([]);
    const [couponError, setCouponError] = useState('');
    const [couponLoading, setCouponLoading] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [calculatingCart, setCalculatingCart] = useState(false);

    useEffect(() => {
        setCoupons(cookies.afreshedCart.coupons);

        if (cookies.raf !== undefined && cookies.raf !== null) {
            checkCoupon(cookies.raf)
        }
    }, [])

    useEffect(() => {
        setCouponsToParent(coupons);
        checkCart();
    }, [coupons])

    const checkCart = () => {

        if (cookies.afreshedCart.coupons !== undefined && coupons.length !== cookies.afreshedCart.coupons.length) {
            // return;
        }

        setCalculatingCart(true);
        const cartUnit = new CartUnit();
        let body = { items: [] };

        cookies.afreshedCart.items.forEach(item => {
            body.items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        });

        if (coupons !== undefined && coupons.length > 0) {
            body.coupons = coupons.map(c => c.code);

            if ((email === null || email === '') && cookies.afreshedCart.customer.email !== undefined) {
                body.email = cookies.afreshedCart.customer.email;
            } else {
                body.email = email;
            }
        }

        cartUnit.checkCart(body, (res) => {
            wrlog("DONE CALC CART!", res);
            setCalculatingCart(false);
            setTotals({ total: res.total, tax: res.tax, totalSubscriptions: res.subscriptions.total, taxSubscriptions: res.subscriptions.tax, totalNormal: res.normal.total, taxNormal: res.normal.tax })
            setSubTotals({ total: res.subtotal, tax: res.subtotalTax, totalSubscriptions: res.subscriptions.subtotal, taxSubscriptions: res.subscriptions.subtotalTax, totalNormal: res.normal.subtotal, taxNormal: res.normal.subtotalTax })
            setCartItems(res.items);
        }, (err) => {
            wrlog("ERR", err)
        })
    }

    useEffect(() => {
        wrlog(totals, subTotals);
    }, [totals, subTotals])

    const renderSubscriptionProductPrice = (regular, actual, deliveryInterval) => {
        if (parseFloat(regular) !== parseFloat(actual)) {
            return `Erste Lieferung: ${formatMoney(actual)}, danach ${deliveryInterval} ${formatMoney(regular)}`;
        }

        return `${deliveryInterval}: ${formatMoney(actual)}`;
    }

    const renderNormalProductPrice = (regular, actual) => {
        if (regular !== actual) {
            return <><del>{formatMoney(regular)}</del> {formatMoney(actual)}</>
        }
        return formatMoney(actual)
    }

    const renderCart = () => {
        if (cartItems.length < 1 || cartItems[0].price === undefined) {
            return <div className={styles.cartItemContainer}>
                <div className={styles.cartItemImage}>
                    <Skeleton variant="rounded" style={{ borderRadius: 5 }} width={100} height={70} />
                </div>
                <div className={styles.cartItemContent}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ marginTop: -6, width: '80%' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ width: '50%' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ width: '30%' }} />
                </div>
            </div>
        }

        return cartItems.map(cartItem => {
            return <div className={styles.cartItemContainer}>
                <div className={styles.cartItemImage}>
                    <img src={cartItem.image} alt="Produktbild" />
                </div>
                <div className={styles.cartItemContent}>
                    <div className={styles.productTitle}>{cartItem.quantity}x {cartItem.title}</div>
                    {calculatingCart ? <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ width: '50%' }} /> : (cartItem.product_type === 'subscription' ? renderSubscriptionProductPrice(cartItem.regular_price, cartItem.price, cartItem.product_data.delivery) : renderNormalProductPrice(cartItem.regular_price, cartItem.price))}
                    {cartItem.product_data !== undefined && cartItem.product_data.nextDeliveryDate !== undefined && <p>Nächste Auslieferung am {cartItem?.product_data.nextDeliveryDate}</p>}
                </div>
            </div>
        })
    }

    const removeCoupon = (code) => {
        let _coupons = [...coupons];
        let index = _coupons.findIndex(c => c.code === code);
        _coupons.splice(index, 1);

        let _cart = cookies.afreshedCart;
        _cart.coupons = _coupons;
        setCookies('afreshedCart', _cart);

        setCoupons(_coupons);
    }

    const checkCoupon = (_couponCode = null) => {
        const couponUnit = new CouponUnit();
        setCouponError('');

        if (_couponCode === null) {
            _couponCode = couponCode;
        }

        if (coupons.findIndex(c => c.code === _couponCode) >= 0) {
            setCouponError('Du hast diesen Gutschein bereits angewendet.');
            return false;
        }

        setCouponLoading(true);

        const body = {
            code: _couponCode,
            customer_id: null,
            email: email,
            cart: cartItems.map(i => ({ product_id: i.product_id, quantity: i.quantity }))
        }

        wrlog("COUP", coupons);
        wrlog("COUP", _couponCode);

        couponUnit.checkCoupon(body, (coupon) => {
            wrlog(coupon)
            setCouponLoading(false);
            let _coupons = [...coupons];

            if (coupon.status === 'valid') {
                _coupons.push({
                    code: _couponCode,
                    ...coupon
                })
                setCoupons(_coupons);
                console.log("_coupons", _coupons);

                let _cart = cookies.afreshedCart;
                _cart.coupons = _coupons;
                setCookies('afreshedCart', _cart);

                setCouponCode('');
            } else {
                setCouponCode('');
                setCoupons(_coupons);
                checkCart();
            }
        }, err => {
            setCouponCode('');
            checkCart();

            let _coupons = [...coupons];

            let index = coupons.findIndex(c => c.code === _couponCode)
            if (index > -1) {
                _coupons.splice(index, 1);
            }

            setCoupons(_coupons);

            setCouponLoading(false);
            setCouponError(err.response.data.exception.message);
            wrlog(err)
        })
    }

    return <div className={`${styles.container} ${mobile !== undefined ? styles.isMobile : undefined}`} ref={initRef}>
        <div className={styles.inner}>
            {mobile === undefined && <h4>Bestellinformationen</h4>}
            {mobile === undefined && renderCart()}
            {mobile === undefined && <>
                <hr />
                <Container fluid style={{ padding: 0 }}>
                    <Row>
                        <Col md={8}>
                            <TextField
                                value={couponCode}
                                onChange={e => setCouponCode(e.target.value)}
                                className={`filled noMargin ${couponLoading ? 'disabled' : undefined} ${couponCode !== undefined && couponCode !== null && couponCode !== '' ? 'valued' : ''}`}
                                id="standard-basic"
                                label={`Gutschein- oder Rabattcode`}
                            />
                        </Col>
                        <Col md={4}>
                            <div
                                onClick={() => checkCoupon()}
                                className={`button secondary ${couponCode === '' || couponLoading ? 'disabled' : undefined}`}
                                style={{
                                    padding: '18px 30px 17px', width: '100%', textAlign: 'center', marginBottom: 0
                                }}>anwenden</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span style={{ color: 'red' }}>
                                {couponError}
                            </span>
                            {coupons !== undefined && coupons !== null && coupons.length > 0 &&
                                <table className={styles.cartTable} style={{ marginTop: 10 }}>
                                    {
                                        coupons.map(coupon => {
                                            return <tr>
                                                <td>{coupon.code} <small style={{ display: 'inline-block' }}>({coupon.amount_type === 'PERCENT' ? '-' + coupon.amount + '%' : '-' + formatMoney(coupon.amount)})</small></td>
                                                <td><div className='deleteButton' onClick={() => removeCoupon(coupon.code)}>entfernen</div></td>
                                            </tr>
                                        })
                                    }

                                </table>
                            }
                        </Col>
                    </Row>
                </Container>
                <hr />
                <table className={styles.cartTable}>
                    <tr>
                        <td>Zwischensumme</td>
                        <td>{subTotals.total && !calculatingCart ? formatMoney(subTotals.total) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</td>
                    </tr>
                    {subTotals.totalSubscriptions > 0 && <tr>
                        <td><small>davon wiederkehrend</small></td>
                        <td><small>{subTotals.totalSubscriptions && !calculatingCart ? formatMoney(subTotals.totalSubscriptions) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</small></td>
                    </tr>}

                    {subTotals.totalNormal > 0 && <tr>
                        <td><small>davon einmalig</small></td>
                        <td><small>{subTotals.totalNormal && !calculatingCart ? formatMoney(subTotals.totalNormal) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</small></td>
                    </tr>}

                    <tr>
                        <td>Versand</td>
                        <td>gratis</td>
                    </tr>
                </table>
                <hr />
            </>
            }

            {mobile === undefined ? <table className={styles.cartTable}>
                <tr>
                    <td><h4>Gesamtsumme</h4></td>
                    <td>{totals.total !== null && !calculatingCart ? <>
                        <div className={styles.priceContainer}>{formatMoney(totals.total)}</div><small>inkl. {formatMoney(totals.tax)} USt.</small></>
                        : <>
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} style={{ display: 'inline-block', width: '100px', height: 40 }} />
                            <br />
                            <Skeleton variant="text" sx={{ fontSize: '2rem' }} style={{ display: 'inline-block', width: '100px', height: 15 }} />
                        </>
                    }</td>
                </tr>
                {(totals.totalSubscriptions > 0 || subTotals.totalSubscriptions > 0) && <tr>
                    <td><small>davon wiederkehrend {(subTotals.totalSubscriptions !== totals.totalSubscriptions ? '(erste Lieferung)' : '')}</small></td>
                    <td><small>{totals.totalSubscriptions !== null && !calculatingCart ? formatMoney(totals.totalSubscriptions) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</small></td>
                </tr>}

                {(totals.totalSubscriptions != subTotals.totalSubscriptions) && <tr>
                    <td><small>danach</small></td>
                    <td><small>{subTotals.totalSubscriptions !== null && !calculatingCart ? formatMoney(subTotals.totalSubscriptions) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</small></td>
                </tr>}

                {(totals.totalNormal > 0 || subTotals.totalNormal) > 0 && <tr>
                    <td><small>davon einmalig</small></td>
                    <td><small>{totals.totalNormal !== null && !calculatingCart ? formatMoney(totals.totalNormal) : <Skeleton variant="text" sx={{ fontSize: '1rem' }} style={{ float: 'right', width: '100px' }} />}</small></td>
                </tr>}
            </table> :
                <table className={styles.cartTable}>
                    <tr onClick={scrollToCart}>
                        <td>{cookies.afreshedCart.items.length} Produkt{cookies.afreshedCart.items.length !== 1 && "e"} im Warenkorb</td>
                        <td>anzeigen</td>
                    </tr>
                    <tr>
                        <td><h5>Gesamtsumme</h5>
                        </td>
                        <td><div className={styles.priceContainer}>{formatMoney(totals.total)}</div><small>inkl. {formatMoney(totals.tax)} USt.</small></td>
                    </tr>
                </table>
            }


            {mobile === undefined && <>
                <br />
                <h4>Informationen zum Abo</h4>
                <table className={styles.iconTable}>
                    <tr>
                        <td><img src={Geld} alt="Geld" style={{ width: "25px" }} /></td>
                        <td>bei Bestellung keine Zahlung erforderlich: Geld wird erst vor der Lieferung abgebucht</td>
                    </tr>
                    <tr>
                        <td><img src={Kalender} alt="Kalender" style={{ width: "25px" }} /></td>
                        <td>Jederzeit pausierbar &amp; kündbar</td>
                    </tr>
                    <tr>
                        <td><img src={Baum} alt="Baum" style={{ width: "25px" }} /></td>
                        <td>Ein gepflanzter Baum pro Lieferung</td>
                    </tr>
                </table>
                <div className={styles.iconRow}>
                    <img src={Mc} alt="mc" />
                    <img src={Visa} alt="Visa" />
                    <img src={PayPal} alt="Paypal" />
                    <img src={Sepa} alt="Sepa" />
                    <img src={Gp} alt="Gp" />
                    <img src={Ap} alt="Ap" />
                    <img src={Ccdc} alt="Ccdc" />
                    <img style={{ width: "30px" }} src={Ae} alt="Ae" />
                </div>
            </>}
        </div>
    </div>
}

export default CartSidebar;