import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import wrlog from '../../helper/functions/wrlog';
import { useForm, Controller as FormController } from "react-hook-form";
import OrderUnit from '../../communicator/http/OrderUnit';
import { useCookies } from 'react-cookie';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';

import styles from '../../elements/quickPay/style.module.scss';

const PaypalBillingAgreementManualRequest = () => {

    const [name, setName] = useState("");
    const [returnUrl, setReturnUrl] = useState(null);

    const params = useParams();

    const paypalBillingAgreementManualRequestToken = params.id;

    const navigate = useNavigate();

    const orderUnit = new OrderUnit();

    useEffect(() => {
        const cbSuccess = (res) => {
            setName(res.name);
            setReturnUrl(res.returnUrl);
            wrlog(res);
        }

        const cbError = () => {
            navigate('/error', { replace: true, state: { type: "undefined" } });
        }
        
        orderUnit.paypalBillingAgreementManualRequest(paypalBillingAgreementManualRequestToken, cbSuccess, cbError);
    }, []);

    if (returnUrl === null) {
        return <Loading visible={true} />
    }

    return <>
        <Container style={{ padding: 0 }} className="alignedCenter">
            <Row style={{ marginRight: 0 }}>
                <Col md={12} style={{ paddingTop: 50, justifyContent: 'center', minHeight: "calc(100vh - 207px)", paddingBottom: 50 }}>
                    <div>
                        <h2 className='normal' style={{ marginBottom: 10, textAlign: 'center' }}>Hallo {name},</h2>
                        <h5 className='normal' style={{ textAlign: 'center' }}>Aufgrund unserer System-Umstellung brauchen wir erneut deine Einverständniserklärung zum Abbuchen deines Paypal-Accounts. Bitte klicke dafür auf den Paypal Button und gib uns die Erlaubnis dein Konto vor jeder Lieferung zu belasten.</h5>
                        <br /><h5 className='normal' style={{ textAlign: 'center' }}>Wir sagen Danke und wünschen dir viel Genuss mit deiner nächsten Retterbox.</h5>
                        <br /><h5 className='normal' style={{ textAlign: 'center' }}>Dein Afreshed-Team</h5>

                        {returnUrl !== null && <div
                            className={`${styles.container}`} style={{border: 0}}
                        ><div
                            className={`${styles.button} ${styles.paypal}`}
                            onClick={() => {
                                window.location.replace(returnUrl);
                            }}
                        ></div></div>}

                    </div>
                </Col>
            </Row>
        </Container>
        <Footer />
    </>

}

export default PaypalBillingAgreementManualRequest;