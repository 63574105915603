import React, { useEffect } from 'react';

import Accordion from '../../accordion';
import Sofort from "../../../assets/sofort_logo.png";
const SofortPayment = ({ open, setOpen, show, setValid }) => {

    const images = <img alt="Sofort Logo" src={Sofort} style={{ height: 28 }} />;

    useEffect(() => {
        setValid(open);
    }, [open]);

    return <Accordion
        open={open}
        show={show}
        id={"sofort"}
        setOpen={setOpen}
        title={"Sofort-Überweisung"}
        rightContent={images}
    >
        <div className='content'>
            <p style={{ marginBottom: 0 }}><strong>Um bei jeder Lieferung automatisch Abbuchen zu können, müssen wir dein Konto mit 0,10€ belasten.<br />Dieser Betrag wird dir bei der ersten Lieferung gutgeschrieben.<br /><br /></strong>Bei klicken auf "Kostenpflichtig bestellen" werden Sie sicher zu SOFORT weitergeleitet.</p>
        </div>
    </Accordion>

}

export default SofortPayment;