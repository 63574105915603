import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, FormControl, TextField } from '@material-ui/core';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { translate } from '../../helper/multilingual';
import CartSidebar from '../../elements/cartSidebar';
import { useForm, Controller as FormController } from "react-hook-form";
import CartUnit from '../../communicator/http/CartUnit';
import CustomerUnit from '../../communicator/http/Customers';
import SettingsUnit from '../../communicator/http/Settings';

import QuickPay from '../../elements/quickPay';
import { useCookies } from 'react-cookie';

import Lock from '../../assets/locked.png';
import PersonalData from '../../assets/PersonalData.png';
import LogoLabel from '../../assets/logolabel.png';
import Haken from '../../assets/arrow-ios-back-fill.png';
import Navigation from '../../elements/navigation';
import Footer from '../../elements/footer';
import wrlog from '../../helper/functions/wrlog';
import EmptyCart from '../emptyCart';
import Message from '../../elements/message';
import addGTMDataLayer from '../../elements/googleTagManager';
import Popup from '../../elements/popup';

const CustomerData = ({ emptyCart }) => {

    const [loading, setLoading] = useState(false);
    const [postcodeBlacklist, setPostcodeBlacklist] = useState([]);
    const [postCodeBlacklistError, setPostcodeBlacklistError] = useState(false);

    const [emailExistsMessageOpen, setEmailExistsMessageOpen] = useState(false);
    const [notifyEmailPopupOpen, setNotifyEmailPopupOpen] = useState(false);
    const [postcode, setPostcode] = useState('');

    const [notificationSendOpen, setNotificationSendOpen] = useState(false);
    const [coupons, setCoupons] = useState([]);

    const { handleSubmit, setValue, control, formState: { errors } } = useForm();
    const { handleSubmit: handleSubmitNotification, setValue: setValueNotification, control: controlNotification, formState: { errors: errorsNotification } } = useForm();

    const [email, setEmail] = useState('');

    const cartRef = useRef();

    const [cookie, setCookie] = useCookies(['afreshedCart']);

    const navigate = useNavigate();

    useEffect(() => {

        addGTMDataLayer({ event: 'initiatecheckout' });
        setValues();

    }, [cookie]);

    useEffect(() => {
        getPostcodeBlacklist();
    }, [])

    const getPostcodeBlacklist = () => {
        const settingsUnit = new SettingsUnit();
        settingsUnit.getPostcodeBlacklist((res) => {
            wrlog("postcodes", res)
            setPostcodeBlacklist(res)
        }, () => { })

    }

    const setValues = () => {
        if (emptyCart) return;

        if (cookie.afreshedCart !== null && cookie.afreshedCart.customer !== undefined) {
            setValue('firstName', cookie.afreshedCart.customer.firstName);
            setValue('lastName', cookie.afreshedCart.customer.lastName);
            setValue('address1', cookie.afreshedCart.customer.address1);
            setValue('streetNumber', cookie.afreshedCart.customer.streetNumber);
            setValue('address2', cookie.afreshedCart.customer.address2);
            setValue('postcode', cookie.afreshedCart.customer.postcode);
            setValue('city', cookie.afreshedCart.customer.city);
            setValue('email', cookie.afreshedCart.customer.email);
            setEmail(cookie.afreshedCart.customer.email);
            setValue('phone', cookie.afreshedCart.customer.phone);
            setValue('legalText', cookie.afreshedCart.customer.legalText);
            setValue('receiveNews', cookie.afreshedCart.customer.receiveNews);

            wrlog("SETEMAIL", cookie.afreshedCart.customer.email)
        }

    }

    /**
     * Submit userData to API
     * @param {object} data 
     */
    const doSubmit = (data) => {

        setLoading(true);

        sendToCart(data)


        // customerUnit.customerEmailExists(data.email, (res) => {

        //     if (!res.exists) {
        //         sendCart();
        //     } else {
        //         emailExists();
        //     }
        // }, () => {
        //     emailExists();
        // })


    }

    const sendToCart = async (data) => {
        const cartUnit = new CartUnit();

        let _cart = { ...cookie.afreshedCart };

        let items = [];

        _cart.items.forEach(item => {
            items.push({
                product_id: item.product_id,
                quantity: item.quantity,
            })
        })

        let body = {
            items: items,
            customer: {
                first_name: data.firstName,
                last_name: data.lastName,
                address_1: data.address1,
                street_number: data.streetNumber,
                address_2: data.address2,
                postcode: data.postcode,
                city: data.city,
                email: data.email,
                phone: data.phone,
                legal_text: data.legalText ? 'true' : 'false',
                receive_news: data.receiveNews ? 'true' : 'false',
            }
        };

        _cart.customer = data;
        _cart.coupons = coupons;
        setCookie('afreshedCart', _cart);

        const cb = (res) => {
            _cart.cartId = res.id;
            setCookie('afreshedCart', _cart);
            navigate("/payment");
        }

        if (_cart.cartId === undefined) {
            cartUnit.saveCart(body, cb, cb);
        } else {
            navigate("/payment");
        }
    }

    const submitNotification = (data) => {
        const cartUnit = new CartUnit();

        const cbSuccess = (res) => {
            setNotifyEmailPopupOpen(false);
            setNotificationSendOpen(true);
        }

        const cbError = (err) => {
        }

        let body = {
            postcode: postcode,
            email: data.email
        }

        cartUnit.setNotification(body, cbSuccess, cbError);
    }

    const postcodeBlacklistUpdate = (e) => {
        setPostcodeBlacklistError(postcodeBlacklist.includes(e.target.value))
        setPostcode(e.target.value)
    }


    if (emptyCart === true) {
        return <EmptyCart />;
    }

    return <>
        <Loading visible={loading} />
        <Container fluid style={{ padding: 0 }}>
            <CartSidebar mobile setCouponsToParent={setCoupons} email={email} scrollToCart={() => {
                const { offsetTop } = cartRef.current;

                window.scrollTo({ top: offsetTop })
            }} />
            <Row style={{ marginRight: 0 }}>
                <Col md={7} style={{}} className={"leftContainerMaxWidthWrapper containerPaddingTop"}>
                    <Container className={"leftContainerMaxWidth"} style={{}}>
                        <Navigation currentStep={0} />
                        {/* <QuickPay />

                        <div className='headlineWithStroke'><h4>oder andere Bezahlmöglichkeit wählen</h4></div>

                        <br />
                        <br /> */}
                        <form onSubmit={handleSubmit(doSubmit)}>
                            <h2 className='normal'>Kontaktinformationen</h2>
                            <Row>
                                <Col md={6}>
                                    <FormController
                                        name={"email"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={e => {
                                                    onChange(e);
                                                    setEmail(e.target.value)
                                                }}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.email}
                                                id="standard-basic"
                                                label={`${translate('email')} *`}
                                                type="email"
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormController
                                        name={"phone"}
                                        rules={{
                                            required: false,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.phone}
                                                id="standard-basic"
                                                label={`${translate('phone')}`}
                                                type="tel"
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ marginTop: 50 }}>
                                    <h2 className='normal'>Lieferadresse</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormController
                                        name={"firstName"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                onChange={onChange}
                                                value={value}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.firstName}
                                                id="standard-basic"
                                                label={`${translate('firstname')} *`}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormController
                                        name={"lastName"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.lastName}
                                                id="standard-basic"
                                                label={`${translate('lastname')} *`}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <FormController
                                        name={"address1"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.address1}
                                                id="standard-basic"
                                                label={`${translate('address')} *`}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormController
                                        name={"streetNumber"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.streetNumber}
                                                id="standard-basic"
                                                label={"Hausnummer *"}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormController
                                        name={"address2"}
                                        rules={{
                                            required: false,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.address2}
                                                id="standard-basic"
                                                label={`${translate('address_additional')}`}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormController
                                        name={"postcode"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    postcodeBlacklistUpdate(e);
                                                }}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.postcode || postCodeBlacklistError}
                                                id="standard-basic"
                                                label={`${translate('postcode')} *`}
                                            />
                                        )}
                                    />
                                    {postCodeBlacklistError &&
                                        <p style={{ color: 'red' }}>Wir liefern leider nicht an deine Adresse. Wir können dich benachrichtigen, sobald wir an deine Postleitzahl senden. <span style={{ cursor: 'pointer' }} onClick={() => setNotifyEmailPopupOpen(true)}><u>Benachrichtigung erhalten</u></span></p>
                                    }
                                </Col>
                                <Col md={6}>
                                    <FormController
                                        name={"city"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <TextField
                                                value={value}
                                                onChange={onChange}
                                                className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                                                error={errors.city}
                                                id="standard-basic"
                                                label={`${translate('city')} *`}
                                            />
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 30, alignItems: 'center' }}>
                                <Col md={12}>
                                    <FormController
                                        name={"legalText"}
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <FormControl
                                                error={errors.legalText}
                                            >
                                                <FormControlLabel
                                                    label={<>Ich habe die <a href="https://afreshed.at/agb" rel="noreferrer" target={'_blank'}>AGB</a> und <a href="https://afreshed.at/datenschutzerklaerung" rel="noreferrer" target={'_blank'}>Datenschutzbestimmungen</a> gelesen, verstanden und akzeptiere diese.</>}
                                                    style={{ marginBottom: 10 }}
                                                    className={`${errors.legalText ? "error" : undefined}`}
                                                    control={
                                                        <Checkbox
                                                            onChange={onChange}
                                                            checked={value || false}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        )}
                                    />
                                    <br />
                                    <FormController
                                        name={"receiveNews"}
                                        rules={{
                                        }}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, onFocus } }) => (
                                            <FormControl
                                            >
                                                <FormControlLabel
                                                    label={<>Ich möchte Neuigkeiten wie beispielsweise Rezeptideen erhalten.</>}
                                                    style={{ marginBottom: 10 }}
                                                    control={
                                                        <Checkbox
                                                            onChange={onChange}
                                                            checked={value || false}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 30, alignItems: 'center' }} className="column-reverse">
                                <Col md={6}>
                                    <a href="https://afreshed.at" style={{ color: 'black', textDecoration: 'none' }}><img src={Haken} alt="Haken" style={{ width: 7, marginRight: 10 }} /> zurück zum Shop</a>
                                </Col>
                                <Col md={6}>
                                    <input type={'submit'} className={`button continueButton ${postCodeBlacklistError ? 'disabled' : undefined}`} value="weiter" />
                                    {/* <div onClick={() => {setLoading(true)}}>load</div> */}
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col md={7} style={{ marginTop: 50, marginBottom: 50 }}>
                                <table className={'iconTable'}>
                                    <tr>
                                        <td><img src={Lock} alt="Lock" style={{ width: "19px" }} /></td>
                                        <td>Sichere Verbindung (SSL)</td>
                                    </tr>
                                    <tr>
                                        <td><img src={PersonalData} alt="Kalender" style={{ width: "25px" }} /></td>
                                        <td>Alle persönlichen Daten werden vertraulich behandelt</td>
                                    </tr>
                                </table>
                            </Col>
                            <Col md={5}>
                                <img alt="Logo" src={LogoLabel} style={{ width: '100%' }} />
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={5} style={{ paddingRight: 0, marginBottom: 0 }}>
                    <CartSidebar initRef={cartRef} email={email} setCouponsToParent={setCoupons} />
                </Col>
            </Row>
        </Container>
        <Footer />
        <Message
            open={emailExistsMessageOpen}
            type={'info'}
            title={'Du hast bereits einen Account?'}
            body={'Du hast bereits einen Account bei uns? Wir arbeiten daran, dass du auch mehrere Produkte von uns kaufen kannst.'}
            buttonText={'OK'}
            buttonAction={() => {
                setEmailExistsMessageOpen(false);
                // navigate('/profile')
            }}
        />
        <Message
            open={notificationSendOpen}
            type={'success'}
            title={'Du wirst benachrichtigt'}
            body={'Wir werden dich benachrichtigen, sobald wir an deine Adresse liefern. Bis dahin wünschen wir dir alles Gute!'}
            buttonText={'OK'}
            buttonAction={() => {
                setNotificationSendOpen(false);
            }}
        />
        <Popup
            open={notifyEmailPopupOpen}
            close={() => setNotifyEmailPopupOpen(false)}
        >
            <h3>Benachrichtigung erhalten</h3>
            <form onSubmit={handleSubmitNotification(submitNotification)}>
                <FormController
                    name={"email"}
                    rules={{
                        required: true,
                    }}
                    control={controlNotification}
                    render={({ field: { onChange, onBlur, value, onFocus } }) => (
                        <TextField
                            value={value}
                            onChange={onChange}
                            className={`filled ${value !== undefined && value !== null && value !== '' ? 'valued' : ''}`}
                            error={errorsNotification.email}
                            id="standard-basic"
                            label={`${translate('email')} *`}
                            type="email"
                        />
                    )}
                />
                <FormController
                    name={"legalText"}
                    rules={{
                        required: true,
                    }}
                    control={controlNotification}
                    render={({ field: { onChange, onBlur, value, onFocus } }) => (
                        <FormControl
                            error={errorsNotification.legalText}
                        >
                            <FormControlLabel
                                label={<>Ich habe die <a href="https://afreshed.at/agb" rel="noreferrer" target={'_blank'}>AGB</a> und <a href="https://afreshed.at/datenschutzerklaerung" rel="noreferrer" target={'_blank'}>Datenschutzbestimmungen</a> gelesen, verstanden und akzeptiere diese.</>}
                                style={{ marginBottom: 10 }}
                                className={`${errorsNotification.legalText ? "error" : undefined}`}
                                control={
                                    <Checkbox
                                        onChange={onChange}
                                        checked={value || false}
                                    />
                                }
                            />
                        </FormControl>
                    )}
                />
                <input type={'submit'} className={`button`} style={{ marginTop: 20 }} value="Absenden" />
            </form>
        </Popup>
    </>

}

export default CustomerData;

