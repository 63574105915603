import Unit from './index';

export default class CartUnit extends Unit {

    getCart = (cartId, cbSuccess, cbError) => {
        this.get(`/cart/${cartId}`, cbSuccess, cbError);
    }

    checkCart = (body, cbSuccess, cbError) => {
        this.post('/cart', body, cbSuccess, cbError);
    }

    saveCart = (cbSuccess, cbError) => {
        this.put(`/cart`, cbSuccess, cbError);
    }

    klarnaCreateSession = (body, cbSuccess, cbError) => {
        this.post(`/cart/klarna`, body, cbSuccess, cbError);
    }

    setNotification = (body, cbSuccess, cbError) => {
        this.post(`/cart/setnotification`, body, cbSuccess, cbError);
    }

}
