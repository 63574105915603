import axios from 'axios';
import { getUserToken } from '../../helper/functions/auth.js';
import wrlog from '../../helper/functions/wrlog.js';
import { API_ENDPOINT } from '../../config.js';


export default class Unit {

    getAuthenticationHeader = () => {
        
        if (getUserToken() === "undefined" || getUserToken() === null || getUserToken() === false) {
            return {}
        }

        return { Authorization: `Bearer ${getUserToken()}` }
    }

    call = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)

        return axios.post(API_ENDPOINT + endpoint, body)
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.data.code === undefined || response.data.code === 200) {
                    cbSuccess(response.data);
                } else {
                    wrlog('ERROR')
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    wrlog("API Error Invalid Data", error.response.data)
                } else {
                    wrlog(error);
                }
                cbError(error);
            });

    }

    get = (endpoint, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        return axios.get(API_ENDPOINT + endpoint, { headers: { ...this.getAuthenticationHeader() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    wrlog("API Error Invalid Data", error.response.data)
                } else {
                    wrlog(error);
                }
                wrlog("ERROR DATA", error.response.data)
                cbError(error);
            });

    }

    post = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        return axios.post(API_ENDPOINT + endpoint, body, { headers: { ...this.getAuthenticationHeader() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    try {
                        cbSuccess(response.data);
                    } catch {
                        wrlog('Error in cbSuccess')
                        cbError(response.data);
                    }

                } else {
                    try {
                        cbError(response.data);
                    } catch {
                        wrlog('Error in cbError')
                    }
                }

                wrlog(response);

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    wrlog("API Error Invalid Data", error.response.data)
                } else {
                    wrlog(error);
                }
                wrlog("ERROR DATA", error.response.data)
                cbError(error);
            });

    }

    put = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        return axios.put(API_ENDPOINT + endpoint, body, { headers: { ...this.getAuthenticationHeader() } })
            .then(function (response) {
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    wrlog("API Error Invalid Data", error.response.data)
                } else {
                    wrlog(error);
                }
                wrlog("ERROR DATA", error.response.data)
                cbError(error);
            });

    }

    delete = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_ENDPOINT + endpoint)

        return axios.delete(API_ENDPOINT + endpoint, { data: body, headers: { ...this.getAuthenticationHeader() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    wrlog("API Error Invalid Data", error.response.data)
                } else {
                    wrlog(error);
                }
                cbError(error);
            });

    }

    show_error = () => {

    }

}