import React, { useEffect } from 'react';

import Accordion from '../../accordion';
import Paypal from "../../../assets/icons/paypalLogo.png";
const PaypalPayment = ({ open, setOpen, show, setValid }) => {

    const images = <><img src={Paypal} alt="Paypal Logo" style={{ height: 23 }} /><small style={{ fontSize: 10, display: 'block', width: '100%' }}>+0,50€ Transaktionsgebühr</small></>;

    useEffect(() => {
        setValid(open);
    }, [open]);

    return <Accordion
        open={open}
        show={show}
        id={"paypal"}
        setOpen={setOpen}
        title={"Paypal"}
        rightContent={images}
        style={{ padding: '7px 20px' }}
    >
        <div className='content'>
            <p style={{ marginBottom: 0 }}>Bei klicken auf den unten erschienen Button werden Sie sicher zu PayPal weitergeleitet</p>
        </div>
    </Accordion>

}

export default PaypalPayment;