import React, { useState, useEffect } from 'react';
import styles from './style.module.scss';

const Popup = ({ children, open, close, style }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {

        if (open) {
            setIsOpen(true);
            setShow(true);
        } else {
            setShow(false);
            setTimeout(() => {
                setIsOpen(false);
            }, 200);
        }
    }, [open]);

    if (!isOpen) {
        return null;
    }

    return <><div onClick={close} className={`${styles.overlay} ${show ? styles.show : undefined}`}></div>
        <div className={`${styles.wrapper} ${show ? styles.show : undefined}`}>
            <div className={`${styles.container} ${show ? styles.show : undefined}`} style={style}>
                <div className={styles.button} onClick={close}></div>
                {children}
            </div>
        </div>
    </>

}

export default Popup;