import { GOOGLE_TAG_MANAGER_ID } from '../../config';
import TagManager from 'react-gtm-module'

const addGTMDataLayer = (dataLayerData) => {
    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        dataLayer: dataLayerData,
    }
    TagManager.dataLayer(tagManagerArgs);
}

export default addGTMDataLayer;